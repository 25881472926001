@fade-in-over-long-duration: 1000ms;

.fade-in-over-long-enter {
  opacity: 0;
}

.fade-in-over-long-enter.fade-in-over-long-enter-active {
  opacity: 1;
  transition: opacity @fade-in-over-long-duration ease-in-out;
}

.fade-in-over-long-exit {
  opacity: 1;
}

.fade-in-over-long-exit.fade-in-over-long-exit-active {
  pointer-events: none;
  opacity: 0;
  transition-delay: @fade-in-over-long-duration;
  transition: opacity @fade-in-over-long-duration ease-in-out;
}
