// This file is the entry point for the app's styles.
// Do not add styles directly in this file.
// Prefer adding styles to `GlobalStyles` (CSS-in-JS).

@import "../node_modules/ripple/styles/ripple.less";
@import "./placeholder.less";
@import "./fade-in-over-long.less";

.ripple-page.startup {
  background-color: white;
  color: #00457c;

  .logo {
    max-width: 50vw !important;
    margin-bottom: 8vh;
  }
}
